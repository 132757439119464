import React from 'react';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber, ColumnOffset } from '../../typings/enum';

import { Copy, Title, Wrapper } from '../../styles';
import { Contacts } from '../../common';

import styles from './workspace.module.css';

const Workspace: React.FC<any> = () => {
  return (
    <Wrapper>
      <Grid>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Title>
              With a privileged<br />location in the centre of<br />the city of Santo Tirso.
            </Title>
          </Column>
        </Row>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Copy>
              We dispose of a physical space, where the tranquillity, the<br />
              serenity and a friendly environment invade the space , thus<br />
              gathering favourable conditions for our employees.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionOne}>
          <Column col={ColumnNumber.five} classes={styles.imgOne}>
            <img src={'/images/workspace1.png'} alt="workspace1.png" />
          </Column>
          <Column col={ColumnNumber.four} offset={ColumnOffset.three}>
            <img src={'/images/workspace2.png'} alt="workspace2.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionTwo}>
          <Column col={ColumnNumber.twelve}>
            <Copy>
              Aware of the comfort and well-being felt in<br />
              our physical space, we are happy to open<br />
              the doors of our office to our customers,<br />
              inviting you to visit our space and offering<br />
              the possibility to work with us.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionThree}>
          <Column col={ColumnNumber.six} offset={ColumnOffset.six}>
            <img src={'/images/workspace3.png'} alt="workspace3.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionFour}>
          <Column col={ColumnNumber.four}>
            <img src={'/images/workspace4.png'} alt="workspace4.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionFive}>
          <Column col={ColumnNumber.twelve}>
            <div className={styles.title}>
              <span className={styles.titleBold}>THE TEAM ALWAYS</span><br />
              will be ready to welcome<br />
              and <span>assist</span> you.
            </div>
          </Column>
        </Row>
        <Row classes={styles.sectionSix}>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one}>
            <Contacts />
          </Column>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default Workspace;
