import styled from "styled-components";

export const Links = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: 60px;

  a {
    color: #000;
    font-family: 'ttcommonsmedium';
    font-size: 24px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
