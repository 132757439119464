import React from 'react';
import clsx from 'clsx';

import styles from './row.module.css';

const Row: React.FC<{ children: any, classes?: any }> = ({ children, classes }) => {
  return (
    <div className={clsx(styles.row, classes && classes)}>
      {children}
    </div>
  )
}

export default Row;
