export enum ColumnNumber {
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four',
  five = 'five',
  six = 'six',
  seven = 'seven',
  eight = 'eight',
  nine = 'nine',
  ten = 'ten',
  eleven = 'eleven',
  twelve = 'twelve'
}

export enum ColumnOffset {
  one = 'offset-by-one',
  two = 'offset-by-two',
  three = 'offset-by-three',
  four = 'offset-by-four',
  five = 'offset-by-five',
  six = 'offset-by-six',
  seven = 'offset-by-seven',
  eight = 'offset-by-eight',
  nine = 'offset-by-nine',
  ten = 'offset-by-ten',
  eleven = 'offset-by-eleven',
}
