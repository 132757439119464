import React from 'react';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber, ColumnOffset } from '../../typings/enum';

import { Copy, Title, Wrapper } from '../../styles';
import { Contacts } from '../../common';

import styles from './about.module.css';

const About: React.FC<any> = () => {
  return (
    <Wrapper>
      <Grid>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Title>
              Transparency and<br />serenity are the core<br />values of our <br />organization.
            </Title>
          </Column>
        </Row>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Copy>
              We're a textile apparel agent, dedicated to the segment of high<br />
              quality and luxurious products, operating our activity on the<br />international segment.
              Our activity is specialized in apparel,<br />footwear and accessories to the male and female market.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionOne}>
          <Column col={ColumnNumber.nine} offset={ColumnOffset.three}>
            <img src={'/images/about1.png'} alt="about1.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionTwo}>
          <Column col={ColumnNumber.nine} offset={ColumnOffset.three}>
            <div className={styles.title}>
              Proud to have a<br />
              name <span>of</span> prestige<br />
              and quality.
            </div>
          </Column>
          <Column col={ColumnNumber.nine} offset={ColumnOffset.three}>
            <Copy>
              We offer to our customers a complete and<br />
              personalized service to their needs and<br />
              expectation, counting,on this end, with a<br />
              multidisciplinar qualified employees and<br />
              equipped with up-to-date knowledge.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionThree}>
          <Column col={ColumnNumber.four}>
            <img src={'/images/about2.png'} alt="about2.png" className={styles.imgTwo} />
          </Column>
          <Column col={ColumnNumber.five} offset={ColumnOffset.three}>
            <img src={'/images/about3.png'} alt="about3.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionFour}>
          <Column col={ColumnNumber.twelve}>
            <Copy>
              As an attentive and innovative<br />
              organization, we have a R&D department,<br />
              seeking to anticipate market trends. In the<br />
              same way and in order to be able to offer<br />
              our customers and partners a high-quality<br />
              service.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionFive}>
          <Column col={ColumnNumber.nine}>
            <img src={'/images/about4.png'} alt="about4.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionSix}>
          <Column col={ColumnNumber.four}>
            <Copy>
              We present a Quality Control<br />
              department, a Merchandising<br />
              department and an interpreter,<br />
              which ensure the proximity of<br />
              nations and languages.
            </Copy>
          </Column>
          <Column col={ColumnNumber.eight}>
            <Copy>
              Concerned and attentive to social<br />
              needs, at LiveFashion we develop<br />
              synergies daily and seek to create<br />
              medium and long-term value for our<br />
              costumers, creating equally social<br />
              and environmental benefits for us<br />
              and our costumers. 
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionSeven}>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one}>
            <Contacts />
          </Column>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default About;
