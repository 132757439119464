import React from 'react';
import clsx from 'clsx';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber } from '../../typings/enum';
import { Logo } from '../../typings/interfaces';

import styles from './menu.module.css';

const Menu: React.FC<Logo> = ({ logoText = "HOME", logoSrc, children }) => {
  return (
    <Grid classes={styles.menuSticky}>
      <Row classes={clsx(styles.menu, process.env.NODE_ENV === 'development' && 'devBorder')}>
        <Column col={ColumnNumber.four}>
          <div className={styles.logo}>
            <a href="/">
              {logoSrc ? <img src={`/logo/${logoSrc}`} alt='' /> : logoText}
            </a>
          </div>
        </Column>
        <Column col={ColumnNumber.eight}>
          {children}
        </Column>
      </Row>
    </Grid>
  )
}

export default Menu;
