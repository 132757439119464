import React from 'react';
import clsx from 'clsx';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber, ColumnOffset } from '../../typings/enum';

import { Copy, Title, Wrapper } from '../../styles';
import { Contacts } from '../../common';

import styles from './sustainability.module.css';

const Sustainability: React.FC<any> = () => {
  return (
    <Wrapper>
      <Grid>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Title>
              We're<br />Sustainable<br />apparel team.
            </Title>
          </Column>
        </Row>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Copy>
              Aware of the social environment that surrounds us and of<br />
              which we are part. At Livefashion we seek to combine our<br />
              work with a social and environmental commitment, trying to<br />
              act on the premise of social responsibility.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionOne}>
          <Column col={ColumnNumber.eight} offset={ColumnOffset.four}>
            <img src={'/images/sustainability1.png'} alt="sustainability1.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionTwo}>
          <Column col={ColumnNumber.four} offset={ColumnOffset.one}>
            <img src={'/images/sustainability2.png'} alt="sustainability2.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionThree}>
          <Column col={ColumnNumber.six} offset={ColumnOffset.six}>
            <img src={'/images/sustainability3.png'} alt="sustainability3.png" />
          </Column>
        </Row>
        <Row classes={clsx(styles.certified, process.env.NODE_ENV === 'development' && 'devBorder', styles.sectionFour)}>
          <Column col={ColumnNumber.six} offset={ColumnOffset.one}>
            <div className={styles.partners}>CERTIFIED PARTNERS.</div>
            <div className={clsx(styles.partners, styles.gots)}>(GOTS, Oeko-tex 100,<br />SA8000, GRS, OCS 100,<br />SMETA);</div>
          </Column>
          <Column col={ColumnNumber.five} classes={styles.green}>
            Compliance with<br />the BCI (Better<br />Cotton Iniciative)
          </Column>
        </Row>
        <Row classes={styles.sectionFive}>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one}>
            <Contacts />
          </Column>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default Sustainability;
