import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import {
  About as AboutPage,
  Contact as ContactPage,
  Home as HomePage,
  Sustainability as SustainabilityPage,
  Team as TeamPage,
  Workspace as WorkspacePage,
} from './pages';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/about">
          <AboutPage />
        </Route>
        <Route exact path="/contact">
          <ContactPage />
        </Route>
        <Route exact path="/sustainability">
          <SustainabilityPage />
        </Route>
        <Route exact path="/team">
          <TeamPage />
        </Route>
        <Route exact path="/workspace">
          <WorkspacePage />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
