import React from 'react';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber, ColumnOffset } from '../../typings/enum';

import { Wrapper } from '../../styles';
import { Contacts } from '../../common';

import styles from './home.module.css';

const Home: React.FC<any> = () => {
  return (
    <Wrapper>
      <Grid>
        <Row classes={styles.sectionOne}>
          <Column col={ColumnNumber.four} offset={ColumnOffset.four}>
            <img src={'/images/home1.png'} alt="home1.png" />
          </Column>
          <Column col={ColumnNumber.twelve} classes={styles.textCopy}>
            <div>A TEXTILE</div>
            <div className={styles.textCopyItalic}>
              Apparel Agent<br />
              for the <span>High</span> Fashion<br />
              Market.
            </div>
          </Column>
        </Row>
        <Row classes={styles.sectionTwo}>
          <Column col={ColumnNumber.three} classes={styles.imgTwo}>
            <img src={'/images/home2.png'} alt="home2.png" />
          </Column>
          <Column col={ColumnNumber.five} offset={ColumnOffset.three} classes={styles.imgThree}>
            <img src={'/images/home3.png'} alt="home3.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionThree}>
          <Column col={ColumnNumber.seven} classes={styles.text}>
            We care about creating synergies<br />
            in order to create social and<br />
            environmental benefits for the<br />
            brands we work with and the<br />
            factories we represent.
          </Column>
        </Row>
        <Row classes={styles.sectionFour}>
          <Column col={ColumnNumber.four} classes={styles.imgFour}>
            <img src={'/images/home4.png'} alt="home4.png" />
          </Column>
          <Column col={ColumnNumber.six} offset={ColumnOffset.two}>
            <img src={'/images/home5.png'} alt="home5.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionFive}>
          <Column col={ColumnNumber.three} offset={ColumnOffset.one} classes={styles.textmin}>
            As a textile<br />
            apparel agent for<br />
            luxury market.
          </Column>
          <Column col={ColumnNumber.six} offset={ColumnOffset.one} classes={styles.text}>
            We collaborate with different<br />
            partners, according to the<br />
            costumer needs.
          </Column>
        </Row>
        <Row classes={styles.sectionSix}>
          <Column col={ColumnNumber.nine} >
            <img src={'/images/home6.png'} alt="home6.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionSeven}>
          <Column col={ColumnNumber.four} offset={ColumnOffset.eight} >
            <img src={'/images/home7.png'} alt="home7.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionEight}>
          <Column col={ColumnNumber.six} >
            <img src={'/images/home8.png'} alt="home8.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionNine}>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one} classes={styles.textoggbold}>
            At Livefashion
          </Column>
        </Row>
        <Row classes={styles.sectionTen}>
          <Column col={ColumnNumber.five} offset={ColumnOffset.one} classes={styles.text}>
            We represent the wishes<br />
            and expectation of our<br />
            costumers and ensure<br />
            response speed and total<br />
            control of the process.
          </Column>
          <Column col={ColumnNumber.six} classes={styles.textmin}>
            The creation of relationship,<br />
            based in trust and security, allows<br />
            us to have a long-term<br />
            relationship with our costumers.
          </Column>
        </Row>
        <Row classes={styles.sectionEleven}>
          <Column col={ColumnNumber.five} offset={ColumnOffset.two}>
            <img src={'/images/home9.png'} alt="home9.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionTwelve}>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one}>
            <Contacts />
          </Column>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default Home;
