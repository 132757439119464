import React from 'react';
import clsx from 'clsx';

import { Footer, Menu } from './common';
import Routes from './Routes';

import { Links } from './styles';

/** devtools grid */
import { DevGrid } from './devtools';

import styles from './app.module.css';

function App() {
  const path = window.location.pathname.split("/");
  return (
    <div className="App">
      {process.env.NODE_ENV === 'development' && <DevGrid />}
      <Menu logoSrc={'logo.svg'}>
        <Links>
          <a href="/sustainability" className={clsx(path[1] === 'sustainability' && styles.active)}>Sustainability.</a>
          <a href="/workspace" className={clsx(path[1] === 'workspace' && styles.active)}>Workspace.</a>
          <a href="/team" className={clsx(path[1] === 'team' && styles.active)}>Team.</a>
        </Links>
        <Links>
          <a href="/about" className={clsx(path[1] === 'about' && styles.active)}>About.</a>
          <a href="/contact" className={clsx(path[1] === 'contact' && styles.active)}>Contact.</a>
        </Links>
      </Menu>
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
