import React from 'react';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber, ColumnOffset } from '../../typings/enum';

import { Copy, Title, Wrapper } from '../../styles';

import styles from './contact.module.css';

const Contact: React.FC<any> = () => {
  return (
    <Wrapper>
      <Grid>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Title>
              To us the costumer<br />proximity is essencial<br />and provides long-<br />term relationships.
            </Title>
          </Column>
        </Row>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Copy>
              The continuous and close contact with the costumer is<br />
              extremely important, we value creating lasting bonds with<br />
              our business partners.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionOne}>
          <Column col={ColumnNumber.eight} offset={ColumnOffset.three}>
            <img src={'/images/contact1.png'} alt="contact1.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionTwo}>
          <Column col={ColumnNumber.five} offset={ColumnOffset.one}>
            <Copy>
              Our facilities are located in the<br />
              heart of Santo Tirso, 20 minutes<br />
              away from Francisco Sá Carneiro<br />
              International Airport, thus<br />
              assuming a privileged location in<br />
              the city centre.
            </Copy>
          </Column>
          <Column col={ColumnNumber.six}>
            <Copy>
              Alongside its geographical<br />
              distribution, in our facilities<br />
              predominates the existence of a<br />
              welcoming, reassuring and family<br />
              environment, which ensures the<br />
              favourable conditions to all that<br />
              know Livefashion.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionThree}>
          <Column col={ColumnNumber.five} classes={styles.imgTwo}>
            <img src={'/images/contact2.png'} alt="contact2.png" />
          </Column>
          <Column col={ColumnNumber.five} offset={ColumnOffset.two}>
            <img src={'/images/contact3.png'} alt="contact3.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionFour}>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one}>
          <div className={styles.title}>
            <span>As a</span> socially<br />
            responsible<br />
            organization.
            </div>
          </Column>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one}>
            <Copy>
              We´re concerned and making efforts to<br />
              create long-term value and benefits for the<br />
              brands we work and represent.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionFive}>
          <Column col={ColumnNumber.nine}>
            <img src={'/images/contact4.png'} alt="contact4.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionSix}>
          <Column col={ColumnNumber.five} offset={ColumnOffset.one}>
          <div className={styles.title}>
            <span className={styles.titleBold}>CONTACT US.</span><br />
            we would<br />
            <span>love</span> to hear<br />
            from you.
          </div>
          </Column>
          <Column col={ColumnNumber.five}>
            <div className={styles.copyBold}>GENERAL.</div>
            <div className={styles.copyNormal}>alcinda@livefashion.pt</div>
            <div className={styles.copyNormal}>
              +351 25 21 03 94 5<br />
              <span>dd.</span> +351 25 21 03 94 20<br />
              <span>mobile.</span> +351 93 60 38 95 8
            </div>
            <div className={styles.copyNormal}>
              <span>address.</span> Rua Ângelo de<br />
              Andrade N. 10 R/C, Fração P<br />
              4780—398, Santo Tirso,<br />
              Portugal.
            </div>
          </Column>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default Contact;
