import styled from "styled-components";

export const Title = styled.div`
  color: #000;
  font-family: 'oggmedium';
  font-size: 100px;
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: 80px;
`;
