import React from 'react';
import clsx from 'clsx';

import styles from './contacts.module.css';

const Contacts: React.FC<any> = () => {
  return (
    <div className={clsx(process.env.NODE_ENV === 'development' && 'devBorder')}>
      <div className={styles.text}>
        Let’s collaborate!<br />Together we do more.
      </div>
      <div className={styles.email}>
        <span className={styles.reachout}>REACH OUT. →</span> <span className={styles.typeface}>✳︎</span> <a href="mailto:alcinda@livefashion.pt">ALCINDA@LIVEFASHION.PT</a>
      </div>
    </div>
  )
}

export default Contacts;
