import React from 'react';
import clsx from 'clsx';

import styles from './grid.module.css';

const Grid: React.FC<{ children: any, classes?: any }> = ({ children, classes }) => {
  return (
    <div className={clsx(styles.container, classes && classes)}>
      {children}
    </div>
  )
}

export default Grid;
