import React from 'react';
import clsx from 'clsx';

import { ColumnNumber, ColumnOffset } from '../../typings/enum';

import styles from './column.module.css';

const Column: React.FC<{ col: ColumnNumber, classes?: any, offset?: ColumnOffset }> = ({ col, children, classes, offset }) => {
  return (
    <div className={clsx(`${styles[col]}`, offset && `${styles[offset]}`, styles.columns, classes && classes)}>
      {children}
    </div>
  )
}

export default Column;
