import React from 'react';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber, ColumnOffset } from '../../typings/enum';

import { Copy, Title, Wrapper } from '../../styles';
import { Contacts } from '../../common';

import styles from './team.module.css';

const Team: React.FC<any> = () => {
  return (
    <Wrapper>
      <Grid>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Title>
              A united team<br />is the secret of<br />our business.
            </Title>
          </Column>
        </Row>
        <Row classes={process.env.NODE_ENV === 'development' && 'devBorder'}>
          <Column col={ColumnNumber.twelve}>
            <Copy>
              Our organization is composed by a multidisciplinary team of<br />
              dedicated and competent professionals, all with many years<br />
              of experience , who daily gather efforts to bring you a reliable<br />
              and personalized service to the needs and expectations of<br />
              our customers, in order to ensure the compliance of your<br />
              requirements and to guarantee a service of total quality<br />satisfaction.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionOne}>
          <Column col={ColumnNumber.four} offset={ColumnOffset.one} classes={styles.imgOne}>
            <img src={'/images/team1.png'} alt="team1.png" />
          </Column>
          <Column col={ColumnNumber.four} offset={ColumnOffset.three}>
            <img src={'/images/team2.png'} alt="team3.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionTwo}>
          <Column col={ColumnNumber.eight} offset={ColumnOffset.four}>
            <img src={'/images/team3.png'} alt="team3.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionThree}>
          <Column col={ColumnNumber.eight} offset={ColumnOffset.four}>
            <div className={styles.title}>
              We only collaborate<br />
              <span>with</span> criative and<br />
              productive minds.
            </div>
          </Column>
          <Column col={ColumnNumber.eight} offset={ColumnOffset.four}>
            <Copy>
              We believe in the spirit of mutual help and the<br />
              collective thinking, enhance the power of sharing<br />
              ideas and opinions, entrusting to our employee’s<br />
              autonomy and independence to carry out their<br />
              daily activities.
            </Copy>
          </Column>
        </Row>
        <Row classes={styles.sectionFour}>
          <Column col={ColumnNumber.ten} offset={ColumnOffset.two}>
            <img src={'/images/team4.png'} alt="team4.png" />
          </Column>
        </Row>
        <Row classes={styles.sectionFive}>
          <Column col={ColumnNumber.eleven} offset={ColumnOffset.one}>
            <Contacts />
          </Column>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default Team;
