import React from 'react';
import clsx from 'clsx';

import { Column, Grid, Row } from '../../components/grid';
import { ColumnNumber } from '../../typings/enum';

import styles from './footer.module.css';

const Footer: React.FC<any> = () => {
  return (
    <Grid>
      <Row classes={clsx(styles.footer, process.env.NODE_ENV === 'development' && 'devBorder')}>
        <Column col={ColumnNumber.six}>
          <div className={styles.copyright}>© 2020. ALL RIGHTS RESERVED.<br />LIVEFASHION.</div>
        </Column>
        <Column col={ColumnNumber.five}>
          <div className={styles.socialmedia}>
            <a href="https://www.instagram.com/livefashion_portugal" target="_blank" rel="noopener noreferrer">Instagram.</a>
            <a href="https://www.facebook.com/livefashionportugal" target="_blank" rel="noopener noreferrer">Facebook.</a>
            <a href="https://www.linkedin.com/company/livefashion" target="_blank" rel="noopener noreferrer">Linkedin.</a>
          </div>
        </Column>
      </Row>
    </Grid>
  )
}

export default Footer;
